
import './App.css';

import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Home from './desktop/screens/bolaget/home';
import Omrade from './desktop/screens/bolaget/omrade';
import Compliance from './desktop/screens/bolaget/compliance';
import OmradesLista from './desktop/screens/bolaget/omradeslista';
import Falt from './desktop/screens/bolaget/falt';
import FaltLista from './desktop/screens/bolaget/faltlista';
import OmOss from './desktop/screens/bolaget/omoss';

import Fraga from './desktop/screens/information/fraga';
import FrageLista from './desktop/screens/information/fragelista';
import Blog from './desktop/screens/information/blog';
import BlogLista from './desktop/screens/information/bloglista';
import { svenska } from './static/strings';
import Gtc from './desktop/screens/legal/gtc';
import Cookies from './desktop/screens/legal/cookies';
import Privacy from './desktop/screens/legal/privacy';
import NotFound from './desktop/screens/legal/notfound';
import Kontakt from './desktop/screens/legal/kontakt';
import Tack from './desktop/screens/legal/tack';
import CookieBanner from './desktop/screens/legal/cookiebanner';
import Priser from './desktop/screens/bolaget/priser';
import AvtalLista from './desktop/screens/bolaget/avtallista';
import StartTillSlut from './desktop/screens/bolaget/starttillslut';
import Avtal2 from './desktop/screens/bolaget/avtal2';
import OmradeTjanst from './desktop/screens/bolaget/omradetjanst';
import Foretagsjurist from './desktop/screens/bolaget/foretagsjurist';



  function App() {
    const sprak =svenska
    
  
  return (
    <div id='root'>

    <div className="App">
      
 <CookieBanner sprak={sprak}/> 
     <Router>

      <Routes>
          <Route path="/" element={<Home sprak={sprak}/>} /> 
          <Route path="/ide/" element={<StartTillSlut steg={1} sprak={sprak}/>} />
          <Route path="/start/" element={<StartTillSlut steg={2} sprak={sprak}/>} />
          <Route path="/tillvaxt/" element={<StartTillSlut steg={3} sprak={sprak}/>} />
          <Route path="/scale/" element={<StartTillSlut steg={4} sprak={sprak}/>} />
          <Route path="/enterprise/" element={<StartTillSlut steg={5} sprak={sprak}/>} />
          <Route path="/exit/" element={<StartTillSlut steg={6} sprak={sprak}/>} />
          <Route path="/priser/" element={<Priser sprak={sprak}/>} />


          <Route path="/foretagsjurist/" element={<Foretagsjurist sprak={sprak}/>} />

          <Route path="/tjanster" element={<AvtalLista sprak={sprak}/>} />          
          <Route path="/tjanster/:tjanstId" element={<Avtal2 sprak={sprak}/>} />          

          <Route path="/omraden/" element={<OmradesLista sprak={sprak}/>} />
          <Route path="/omraden/:omradeId" element={<Omrade sprak={sprak}/>} />
          <Route path="/omraden/:omradeId/:omradeTjanst" element={<OmradeTjanst sprak={sprak}/>} />
          <Route path="/branscher" element={<FaltLista sprak={sprak}/>} />
          <Route path="/branscher/:branschId" element={<Falt sprak={sprak}/>} />
          <Route path="/compliance" element={<Compliance sprak={sprak}/>} />


          <Route path="/kontakt" element={<Kontakt sprak={sprak}/>} />
          <Route path="/tack" element={<Tack sprak={sprak}/>} />

  
          <Route path="/omoss" element={<OmOss sprak={sprak}/>} />

          <Route path="/vanliga-fragor/:fragaId" element={<Fraga sprak={sprak}/>} />
          <Route path="/vanliga-fragor" element={<FrageLista sprak={sprak}/>} />
          <Route path="/blogg/:blogId" element={<Blog sprak={sprak}/>} />
          <Route path="/blogg" element={<BlogLista sprak={sprak}/>} />   



          <Route path="/integritet" element={<Privacy sprak={sprak}/>} />   
          <Route path="/cookies" element={<Cookies sprak={sprak}/>} />   
          <Route path="/allmanna-villkor" element={<Gtc sprak={sprak}/>} />   
          <Route path='*' element={<NotFound sprak={sprak} />} />
   
          <Route path='/404' element={<NotFound sprak={sprak}/>}/>
        </Routes>
        </Router>

    </div>
    </div>
  );
}

export default App;
