import React from "react";
import { Box, Typography, Rating } from "@mui/material";

const GoogleReviewBanner = (props) => {
  return (
    <Box
    sx={{
        backgroundColor: "#1A76C2;", // Dark background
        color: "#fff",
        display:props.hasScrolled ? "none":"flex",
        alignItems: "center",
        justifyContent: "center",
        height: "30px",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,

        transition:"0.1s",

        zIndex: 300,
      }}
    >
      {/* Google Logo Text */}
      <Typography sx={{ fontWeight: 400, fontSize: "15px", marginRight: "10px" }}>
        Google
      </Typography>

      {/* Star Rating */}
      <Rating
        value={5}
        readOnly
        precision={0.5}
        sx={{
          color: "#FFD700",
          fontSize: "15px",
          marginRight: "10px",
        }}
      />

      {/* Review Score & Text */}
      <Typography sx={{ fontWeight: 400, fontSize: "15px" }}>
        4.95/5
      </Typography>
    </Box>
  );
};

export default GoogleReviewBanner;
