import React from 'react';
import "./css/fokusgrid.css"
const FokusGrid = ({ items }) => {
  return (
<div className="fokus-grid-grid">
  {items.map((item, index) => (
    <div key={index} className="fokus-grid">
      {/* Background Image Layer */}
      <div className="fokus-grid-image">
        <img 
          src={`https://backend.lundberglaw.com:443${item.imgurl}`} 
          alt={item.title} 
          loading="lazy"
        />
      </div>

      {/* Text Content */}
      <div className="fokus-grid-content">
        <h2 className="fokus-grid-title">{item.title}</h2>
        <p
          className="fokus-grid-description"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>
    </div>
  ))}
</div>

  );
};

export default FokusGrid;
