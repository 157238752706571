import React from 'react';
import './css/threesteps.css';

const steps = [
  {
    number: 1,
    title: 'Första kontakt',
    description: `Skicka in ett meddelande eller ring oss och berätta kort om ditt ärende. Ju tydligare bild vi får från början, desto snabbare kan vi hjälpa dig rätt. Har du en preferens för hur vi kontaktar dig – telefon eller e-post – skriv gärna det.`,
  },
  {
    number: 2,
    title: 'Behovsanalys',
    description: `Vi bokar in ett första möte där vi går igenom din situation och vad ärendet gäller. Ibland behöver vi ta del av dokument eller ställa följdfrågor för att kunna göra en korrekt bedömning.`,
  },
  {
    number: 3,
    title: 'Du får ett prisförslag',
    description: `Efter mötet återkommer vi med ett tydligt prisförslag. I enklare ärenden kan du få en fast kostnad direkt – annars föreslår vi en lösning utifrån omfattning och behov.`,
  }
];

const ThreeSteps = (props) => {
  return (
    <section className="three-step-process">
      <div className="three-step-header">
        <h2>Första steget till juridisk hjälp</h2>
        <button onClick={props.onclick} className="three-step-button">Kontakta oss</button>
      </div>
      <div className="three-step-grid">
      {steps.map((step, index) => (
  <div key={step.number} className="three-step-card">
    <div className="three-step-number-row">
      <div className="three-step-number">{step.number}</div>
      <div className="three-step-line">
      <svg
  class="three-step-arrow"
  viewBox="0 0 200 24"
  preserveAspectRatio="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <line x1="0" y1="12" x2="190" y2="12" stroke="#2CA9BC" stroke-width="1.5" stroke-linecap="round" />
  <polyline points="190,8 196,12 190,16" stroke="#000" stroke-width="1.5" fill="none" stroke-linecap="round" stroke-linejoin="round" />
</svg>


      </div>
    </div>

    <h3 className="three-step-title">{step.title}</h3>
    <p className="three-step-description">{step.description}</p>
  </div>
))}


      </div>
    </section>
  );
};

export default ThreeSteps;
