import React, { useState } from 'react';
import "./css/quotereview.css";
const QuoteReview = ({ heading, paragraph, reviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const item = reviews[currentIndex];

  const next = () => setCurrentIndex((prev) => (prev + 1) % reviews.length);
  const prev = () => setCurrentIndex((prev) => (prev - 1 + reviews.length) % reviews.length);

  return (
    <div className="quote-review-wrapper">
      <button onClick={prev} className="quote-review-arrow quote-review-arrow-left">←</button>

      <div className="quote-review-container">
        <h2 dangerouslySetInnerHTML={{ __html: heading }} />

        {item && (
          <div className="quote-review-content">
            <p className="quote-review-text">{item.review}</p>
            <div className="quote-review-info-logo">
              <img
                src={item.img}
                alt="Logo"
                loading="lazy"
                className="quote-review-logo"
              />
              <div className="quote-review-info">
                <div className="quote-review-name">{item.name}</div>
                <div className="quote-review-title">{item.title}</div>
                <div className="quote-review-stars">{'★'.repeat(item.stars)}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <button onClick={next} className="quote-review-arrow quote-review-arrow-right">→</button>
    </div>
  );
};

export default QuoteReview;
