


import React from "react";

import karta from "../../../../static/img/karta.webp"
import "./css/mapcomponent.css"
const MapComponent = () => {
  return (
 
       
      <div className="office-locations">
  <div className="office-header">
    <h1>En modern juristbyrå med digitalt fokus</h1>




    <h4>
    Vi hjälper företag i hela Sverige med rätt juridisk expertis sedan 2017, med över 30 års juridisk erfarenhet i ryggen.
      
      </h4>
    <p className="description">
    Vår digitala arbetsmodell gör det enkelt för oss att erbjuda kvalificerad juridisk rådgivning till företag – oavsett var i landet ni befinner er. Med fokus på flexibilitet, tillgänglighet och affärsförståelse anpassar vi oss efter era behov, från norr till söder.
    Genom att kombinera flexibilitet och expertis kan vi möta dina behov, oavsett var du befinner dig.
    </p>
    <p className="description">
    Vi arbetar främst digitalt för att kunna ge snabb, effektiv och personlig service till kunder i hela Sverige.
    </p>
  
</div>

      <img 
      src={karta} 
      alt="Map" 

      loading="lazy" 
      className="map-image"
    />

    </div>
  );
};

/*
   <div className="office-locations">
      <div className="office-header">
       
        <h1>En digital juristbyrå</h1>
        <h4>Vi har sedan vår start 2017 jobbat nationellt med fokus på rätt kompetens för rätt företag</h4>
        <p className="description">
          Våra jurister är baserade i olika ändar av Sverige, genom att välja att jobba digitalt har vi haft möjligheten att välja 
          och erbjuder expert juridisk rådgivning till kunder både lokalt och nationellt.
        </p>
        <p className="description">
          Vi arbetar främst digitalt, så vi kan samarbeta med dig oavsett var du befinner dig.
          Vi kan dock ordna ett personligt möte på våra kontor eller på en plats som du väljer.
        </p>
      </div>

*/

export default MapComponent;
